import { Table, Pane, majorScale, SelectMenu, Button } from 'evergreen-ui';
import { filter } from 'fuzzaldrin-plus';
import React, { useContext, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { ApiMarket } from '../api';
import { CryptoContext } from '../context/CryptoContext';
import { calculate24HourChange } from '../helpers/exchanges';

export interface ICryptoTableProps {
  markets: Array<ApiMarket>;
  handleRowClick: (item: ApiMarket) => void;
}

enum Order {
  NONE = 'NONE',
  ASC = 'ASC',
  DESC = 'DESC',
}

enum Filters {
  GAINERS = 'Top Gainers',
  LOSERS = 'Top Losers',
}

// https://github.com/segmentio/evergreen/blob/master/docs/src/components/examples/AdvancedTable.js

export function CryptoTable(props: ICryptoTableProps) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [orderedColumn, setOrderedColumn] = useState<number>(1);
  const [ordering, setOrdering] = useState<Order>(Order.NONE);
  const [sortProperty, setSortProperty] = useState();
  const [activeFilter, setActiveFilter] = useState<Filters>();
  const { exchanges } = useContext(CryptoContext);

  const sort = (items: Array<ApiMarket>) => {
    // Return if there's no ordering.
    if (ordering === Order.NONE) return items;

    // Get the property to sort each profile on.
    // By default use the `name` property.
    let propKey = 'name';

    return items.sort((a: any, b: any) => {
      let aValue: any = a[propKey];
      let bValue: any = b[propKey];

      // Parse money as a number.
      const isMoney = aValue.indexOf('$') === 0;

      if (isMoney) {
        aValue = Number(aValue.slice(1));
        bValue = Number(bValue.slice(1));
      }

      // Support string comparison
      const sortTable = { true: 1, false: -1 };

      // Order ascending (Order.ASC)
      if (ordering === Order.ASC) {
        return aValue === bValue ? 0 : aValue > bValue ? 1 : -1;
      }

      // Order descending (Order.DESC)
      return bValue === aValue ? 0 : bValue > aValue ? 1 : -1;
    });
  };

  const filterItems = (items: any) => {
    const query = searchQuery.trim();

    // If the searchQuery is empty, return the profiles as is.
    if (query.length === 0) return items;

    return items.filter((item: any) => {
      // Use the filter from fuzzaldrin-plus to filter by name.
      const result = filter(
        [item.base_currency, item.quote_currency, item.exchange],
        query
      );
      return result.length === 1;
    });
  };

  const getIconForOrder = (order: Order) => {
    switch (order) {
      case Order.ASC:
        return 'arrow-up';
      case Order.DESC:
        return 'arrow-down';
      default:
        return 'caret-down';
    }
  };

  const handleFilterChange = (value: string) => {
    setSearchQuery(value);
  };

  const renderRow = ({ item }: { item: ApiMarket }) => {
    return (
      <Table.Row
        className="crypto-table-row"
        key={item.market_id}
        onClick={() => props.handleRowClick(item)}
      >
        <Table.Cell display="flex" alignItems="center" color="rgb(74,74,74)">
          <Pane>
            <Pane fontSize={14} fontWeight="bold">
              {item.base_currency}
              {item.quote_currency}
            </Pane>
          </Pane>
        </Table.Cell>
        <Table.Cell
          display="flex"
          alignItems="center"
          color="rgb(74,74,74)"
          fontSize={14}
        >
          {item.last}
        </Table.Cell>
        <Table.Cell
          visibility={
            Number.isNaN(calculate24HourChange(item)) ? 'hidden' : 'visible'
          }
          display="flex"
          alignItems="center"
          color={calculate24HourChange(item) > 0 ? '#00A97F' : '#FF173E'}
          fontSize={14}
        >
          {calculate24HourChange(item).toFixed(2)}%
        </Table.Cell>
        <Table.Cell
          display="flex"
          alignItems="center"
          color="rgb(74,74,74)"
          fontSize={14}
        >
          {item.high_24h}
        </Table.Cell>
        <Table.Cell
          display="flex"
          alignItems="center"
          color="rgb(74,74,74)"
          fontSize={14}
        >
          {item.low_24h}
        </Table.Cell>
        <Table.Cell
          display="flex"
          alignItems="center"
          color="rgb(74,74,74)"
          fontSize={14}
        >
          {item.quote_volume_24h}
        </Table.Cell>
        <Table.Cell
          display="flex"
          alignItems="center"
          color="rgb(74,74,74)"
          fontSize={14}
        >
          {exchanges[item.exchange].name}
        </Table.Cell>
      </Table.Row>
    );
  };

  const items = filterItems(sort(props.markets));

  return (
    <Pane display="flex" flexDirection="column" height="100%" width="100%">
      <Pane height={60} display="flex" alignItems="center" paddingX={12}>
        <SelectMenu
          hasTitle={false}
          hasFilter={false}
          title="Select name"
          options={Object.values(Filters).map((label) => ({
            label,
            value: label,
          }))}
          selected={activeFilter}
          onSelect={(item: any) => {
            setActiveFilter(item.value);
          }}
        >
          <Button>{activeFilter || 'Filters'}</Button>
        </SelectMenu>
      </Pane>
      <Pane flex="auto">
        <AutoSizer>
          {({ width, height }) => {
            return (
              <Table>
                <Table.Head width={width} backgroundColor="#F7F8FA">
                  <Table.SearchHeaderCell
                    onChange={handleFilterChange}
                    value={searchQuery}
                    width={50}
                  />
                  <Table.HeaderCell
                    fontSize={11}
                    fontWeight={700}
                    textTransform="uppercase"
                    color="rgb(79, 89, 102)"
                  >
                    Last
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    fontSize={11}
                    fontWeight={700}
                    textTransform="uppercase"
                    color="rgb(79, 89, 102)"
                  >
                    Change (24h)
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    fontSize={11}
                    fontWeight={700}
                    textTransform="uppercase"
                    color="rgb(79, 89, 102)"
                  >
                    High
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    fontSize={11}
                    fontWeight={700}
                    textTransform="uppercase"
                    color="rgb(79, 89, 102)"
                  >
                    Low
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    fontSize={11}
                    fontWeight={700}
                    textTransform="uppercase"
                    color="rgb(79, 89, 102)"
                  >
                    Volume
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    fontSize={11}
                    fontWeight={700}
                    textTransform="uppercase"
                    color="rgb(79, 89, 102)"
                  >
                    Exchange
                  </Table.HeaderCell>
                </Table.Head>
                <Table.VirtualBody height={height - 32} width={width}>
                  {items
                    .filter((i: ApiMarket) => {
                      return i.last !== null && i.last.length;
                    })
                    .map((item: ApiMarket) => renderRow({ item }))}
                </Table.VirtualBody>
              </Table>
            );
          }}
        </AutoSizer>
      </Pane>
    </Pane>
  );
}
