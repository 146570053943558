import { ResolutionString } from './charting_library/charting_library.min';

import { ApiCandlestickInterval } from '../api';

export interface CandleStick {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}

export const SUPPORTED_RESOLUTIONS = ['1', '3', '30', '60', '1D'];

export function apiResponseToCandleStick(response: Array<any>): CandleStick {
  return {
    time: parseFloat(response[0]),
    open: parseFloat(response[1]),
    high: parseFloat(response[2]),
    low: parseFloat(response[3]),
    close: parseFloat(response[4]),
    volume: parseFloat(response[5]),
  };
}

export function convertInterval(
  resolution: ResolutionString
): ApiCandlestickInterval {
  if (resolution === '1') {
    return ApiCandlestickInterval._1M;
  } else if (resolution === '3') {
    return ApiCandlestickInterval._3M;
  } else if (resolution === '5') {
    return ApiCandlestickInterval._5M;
  } else if (resolution === '30') {
    return ApiCandlestickInterval._30M;
  } else if (resolution === '60') {
    return ApiCandlestickInterval._1H;
  } else if (resolution === 'D') {
    return ApiCandlestickInterval._1D;
  }
  throw new Error('unsupported resolution');
}
