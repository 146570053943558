import React from 'react';
import { AuthClass } from 'aws-amplify';
import { User } from '../types/utils';

const auth = {
  user: null,
  auth: null,
  handleLogin: () => {},
  handleLogout: () => {},
  handleRegister: () => {},
};

interface AuthContext {
  user: User | false;
  auth: AuthClass | null;
  handleLogin: () => void;
  handleLogout: () => void;
  handleRegister: () => void;
}

export const AuthContext = React.createContext<AuthContext>(auth);
