import { Auth } from 'aws-amplify';
import {
  ApiBalance,
  ApiMarket,
  BalancesApi,
  EventType,
  MarketsApi,
  ServerMessage,
} from '../api';
import WebSocketObserver from '../socket/WebSocketObserver';

export interface Balance {
  exchange: string;
  currency: string;
  balance: string;
  available: string;
  unavailable: string;
}

const USD_CURRENCIES = ['USD', 'USDT', 'TUSD', 'USDC', 'USDS', 'DAI', 'PAX'];

class BalancesStore implements WebSocketObserver {
  private marketsApi = new MarketsApi();

  private _balances?: Array<ApiBalance>;
  private callbacks: Set<(balances: Array<ApiBalance>) => void> = new Set();

  get balances(): Array<ApiBalance> {
    return this._balances || [];
  }

  set balances(value: Array<ApiBalance>) {
    this._balances = value.filter(b => parseFloat(b.balance) > 0);
    for (const callback of this.callbacks) {
      callback(this._balances);
    }
  }

  public getBalance(exchange: string, currency: string): ApiBalance {
    for (const balance of this.balances) {
      if (balance.exchange === exchange && balance.currency === currency) {
        return balance;
      }
    }
    return {
      account_id: 'fake_account_id',
      exchange: exchange,
      currency: currency,
      available: '0.00',
      balance: '0.00',
      unavailable: '0.00',
    };
  }

  addCallback(callback: (balances: Array<ApiBalance>) => void) {
    this.callbacks.add(callback);
  }

  removeCallback(callback: (balances: Array<ApiBalance>) => void) {
    this.callbacks.delete(callback);
  }

  async getUsdBalance(balance: any): Promise<string> {
    if (USD_CURRENCIES.indexOf(balance.currency) > -1) {
      return balance.balance;
    }
    let btc_market: any;
    for (const market of balance.markets) {
      if (USD_CURRENCIES.indexOf(market.quote_currency) > -1) {
        return (parseFloat(balance.balance) * parseFloat(market.last)).toFixed(
          8
        );
      }
      if (market.quote_currency === 'BTC') {
        btc_market = market;
      }
    }
    if (btc_market) {
      const market = await this.marketsApi.getMarket(
        balance.exchange,
        'BTC',
        'USDT'
      );
      console.log(
        balance.currency,
        balance.balance,
        btc_market.last,
        market.last
      );
      return (
        parseFloat(balance.balance) *
        parseFloat(btc_market.last) *
        parseFloat(market.last)
      ).toFixed(8);
    }
    return '0.00';
  }

  onOpen(event: Event) {
    Auth.currentSession().then(session => {
      const balancesApi = new BalancesApi({
        accessToken: session.getAccessToken().getJwtToken(),
      });
      balancesApi.getBalances().then((balances: any) => {
        this.balances = balances;

        for (const balance of balances) {
          this.marketsApi
            .getMarkets(balance.exchange, balance.currency)
            .then(async markets => {
              console.log(balance, markets);
              balance.markets = markets;
              balance.usd_balance = await this.getUsdBalance(balance);
              // todo hack to call update
              this.balances = this.balances;
            });
        }
      });
    });
  }

  onMessage(message: ServerMessage) {
    if (message.event === EventType.BALANCE) {
      const balance: ApiBalance = message.event_data;
      if (parseFloat(balance.balance) > 0) {
        this.balances = this.balances.map(b =>
          b.exchange === balance.exchange && b.currency === balance.currency
            ? balance
            : b
        );
      } else {
        this.balances = this.balances.filter(
          b =>
            b.exchange !== balance.exchange || b.currency !== balance.currency
        );
      }
    }
  }

  onClose(event: CloseEvent) {}
}

export default BalancesStore;
