import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import {
  Checkbox,
  IconButton,
  majorScale,
  minorScale,
  Pane,
  Spinner,
  TextInputField,
} from 'evergreen-ui';
import React, { useEffect, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import { Logo } from './Logo';

type ValidateStatuses = 'success' | 'warning' | 'error' | 'validating' | '';

export interface ILoginScreenProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (user: CognitoUser) => void;
  onError?: () => void;
}

export function LoginScreen(props: ILoginScreenProps) {
  const [remember, setRemember] = useState(false);
  const screenRef = useRef<Pane>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [challenge, setChallenge] = useState<any>('');
  const [challengeLoading, setChallengeLoading] = useState(false);
  const [code, setCode] = useState('');
  const [user, setUser] = useState<CognitoUser>();
  const [loginError, setLoginError] = useState<ValidateStatuses>('');
  const [challengeError, setChallengeError] = useState<ValidateStatuses>('');
  const challengeRef = useRef<any>(null);

  useEffect(() => {
    if (props.isOpen) {
      if (screenRef.current) {
        const el = findDOMNode(screenRef.current) as HTMLElement;
        setTimeout(() => {
          el.classList.add('is-open');
        }, 0);

        el.addEventListener('transitionend', () => {
          el.querySelector('input')!.focus();
        });
      }
    }
  }, [props.isOpen, screenRef]);

  const resetLogin = () => {
    setLoading(false);
    setChallengeLoading(false);
    setCode('');
    // setEmail("");
    // setPassword("");
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const user = await Auth.signIn(email, password);
      console.log('[handleLogin]: ', user);
      setUser(user);
      if (
        user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        setLoading(false);
        setChallenge(user.challengeName);
        challengeRef && challengeRef!.current!.focus();
      } else {
        if (user) {
          props.onSuccess(user);
        }
      }
    } catch (err) {
      setLoginError('error');
      resetLogin();
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  };

  const handleChallengeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const loggedUser = await Auth.confirmSignIn(user, code, challenge);
      if (loggedUser) {
        props.onSuccess(loggedUser);
      } else {
        console.log('something happened');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setChallengeError('error');
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChallengeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  if (!props.isOpen) {
    return null;
  }

  return (
    <Pane
      className="screen"
      ref={screenRef}
      position="fixed"
      left={0}
      right={0}
      top={0}
      bottom={0}
      display="flex"
      justifyContent="center"
      paddingTop="18vh"
      role="dialog"
      aria-modal="true"
    >
      <Pane position="absolute" top={majorScale(2)} right={majorScale(2)}>
        <IconButton
          className="close-button"
          appearance="minimal"
          icon="cross"
          iconSize={36}
          onClick={props.onClose}
        />
      </Pane>
      <Pane className="screen-inner login">
        <Pane textAlign="center" marginBottom={majorScale(4)}>
          <Pane marginBottom={majorScale(4)}>
            <Logo />
          </Pane>
          <Pane is="h1" fontWeight={100} color="white">
            LOG IN
          </Pane>
        </Pane>
        <Pane
          maxWidth={300}
          marginX="auto"
          pointerEvents={loading ? 'none' : 'all'}
          position="relative"
        >
          {loading && (
            <Pane
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner className="loading" />
            </Pane>
          )}
          {challenge ? (
            <Pane
              is="form"
              onSubmit={handleChallengeSubmit}
              opacity={challengeLoading ? 0.3 : 1}
            >
              <Pane marginBottom={majorScale(6)}>
                <TextInputField
                  key="challenge"
                  isInvalid={false}
                  description="Enter code"
                  onChange={handleChallengeChange}
                  name="challenge"
                  id="challenge"
                  innerRef={challengeRef}
                  // validationMessage="This field is required"
                />
              </Pane>
              <Pane display="flex" justifyContent="flex-end">
                <Pane
                  flex="none"
                  is="button"
                  type="submit"
                  className="outline-button"
                  padding={minorScale(1)}
                  minWidth={100}
                  fontSize={16}
                  disabled={challengeLoading}
                >
                  Submit
                </Pane>
              </Pane>
            </Pane>
          ) : (
            <Pane is="form" onSubmit={handleLogin} opacity={loading ? 0.3 : 1}>
              <Pane marginBottom={majorScale(6)}>
                <TextInputField
                  isInvalid={false}
                  description="Email"
                  onChange={handleEmailChange}
                  autoFocus
                  // validationMessage="This field is required"
                />
                <TextInputField
                  isInvalid={false}
                  description="Password"
                  onChange={handlePasswordChange}
                  // validationMessage="This field is required"
                  type="password"
                />
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Pane display="flex" alignItems="center">
                  <Checkbox
                    checked={remember}
                    onChange={e => setRemember(!remember)}
                    marginRight={majorScale(2)}
                  />
                  <Pane color="white">Remember Me</Pane>
                </Pane>
                <Pane
                  flex="none"
                  is="button"
                  type="submit"
                  className="outline-button"
                  padding={minorScale(1)}
                  minWidth={100}
                  fontSize={16}
                  disabled={loading}
                >
                  Login
                </Pane>
              </Pane>
            </Pane>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
}
