import {
  majorScale,
  Menu,
  Pane,
  Popover,
  Position,
  Table,
  TextInput,
  Tooltip,
  Button,
} from 'evergreen-ui';
import React, { useState, useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';
import { ApiAlert, ApiTicker } from '../api';
import { TabHeader } from './TabHeader';

export interface IAlertsTabProps {
  alerts: ApiAlert[];
  addAlert: (price: number) => void;
  deleteAlert: (alert: ApiAlert) => void;
  base: string;
  quote: string;
  exchange: string;
  ticker?: ApiTicker;
  crosshairValue?: number;
}

export const AlertsTab: React.FC<IAlertsTabProps> = (props) => {
  const [price, setPrice] = useState<string>('');

  useEffect(() => {
    if (props.crosshairValue) {
      setPrice(String(props.crosshairValue));
    }
  }, [props.crosshairValue]);

  const renderRowMenu = (alert: ApiAlert) => () => {
    return (
      <Menu>
        <Menu.Group>
          <Menu.Item intent="danger" onSelect={() => props.deleteAlert(alert)}>
            Delete...
          </Menu.Item>
        </Menu.Group>
      </Menu>
    );
  };

  const calculateDelta = (alert: ApiAlert) => {
    if (props.ticker) {
      return (
        ((Number(alert.price) - Number(props.ticker.last)) /
          Number(props.ticker.last)) *
        100
      );
    }
    return 0;
  };

  const setShortcut = (percentage: number) => {
    if (props.ticker) {
      setPrice(String(percentage * Number(props.ticker.last)));
    }
  };

  const alerts = props.alerts.reduce<{
    primary: ApiAlert[];
    other: ApiAlert[];
  }>(
    (acc, curr) => {
      if (
        curr.base_currency === props.base &&
        curr.quote_currency === props.quote &&
        curr.exchange === props.exchange
      ) {
        acc.primary.push(curr);
      } else {
        acc.other.push(curr);
      }
      return acc;
    },
    {
      primary: [],
      other: [],
    }
  );

  return (
    <Pane>
      <TabHeader>Alerts</TabHeader>
      <Pane padding={majorScale(1)}>
        <Pane
          display="flex"
          justifyContent="space-around"
          marginBottom={majorScale(1)}
          userSelect="none"
        >
          <Button
            appearance="minimal"
            height={16}
            marginRight={6}
            onClick={() => setShortcut(0.9)}
          >
            -10%
          </Button>
          <Button
            appearance="minimal"
            height={16}
            marginRight={6}
            onClick={() => setShortcut(0.95)}
          >
            -5%
          </Button>
          <Button
            appearance="minimal"
            height={16}
            marginRight={6}
            onClick={() => setShortcut(1)}
          >
            Current
          </Button>
          <Button
            appearance="minimal"
            height={16}
            marginRight={6}
            onClick={() => setShortcut(1.05)}
          >
            5%
          </Button>
          <Button
            appearance="minimal"
            height={16}
            onClick={() => setShortcut(1.1)}
          >
            10%
          </Button>
        </Pane>
        <Pane display="flex" fontSize={12} color="var(--subheader-gray)">
          <Pane flex="auto" marginRight={majorScale(1)}>
            <Tooltip content="Click chart to set price">
              <TextInput
                name="alert-price-entry"
                placeholder="Price"
                type="number"
                value={price}
                onChange={(e: any) => {
                  setPrice(e.target.value);
                }}
                width="100%"
              />
            </Tooltip>
          </Pane>
          <Button
            appearance="primary"
            onClick={() => {
              if (price) {
                props.addAlert(Number(price));
                setPrice('');
              }
            }}
          >
            Add
          </Button>
        </Pane>
      </Pane>
      <Table>
        <Table.Head marginRight={0} paddingRight={0}>
          <Table.TextHeaderCell flex={1.5} paddingX={majorScale(1)}>
            Symbol
          </Table.TextHeaderCell>
          <Table.TextHeaderCell paddingX={majorScale(1)}>
            Delta (%)
          </Table.TextHeaderCell>
          <Table.TextHeaderCell paddingX={majorScale(1)}>
            Alert
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {uniqBy(alerts.primary, 'alert_id')
            .sort((a, b) => {
              let d1 = calculateDelta(a);
              let d2 = calculateDelta(b);
              if (d1 > d2) {
                return -1;
              }
              return 1;
            })
            .map((alert) => {
              if (!props.ticker) {
                return;
              }
              const delta =
                ((Number(alert.price) - Number(props.ticker.last)) /
                  Number(props.ticker.last)) *
                100;

              return (
                <Popover
                  content={renderRowMenu(alert)}
                  position={Position.BOTTOM_RIGHT}
                  key={alert.alert_id}
                >
                  <Table.Row isSelectable>
                    <Table.TextCell flex={1.5} paddingX={majorScale(1)}>
                      <Pane display="flex" flexDirection="column">
                        <Pane fontSize={14} fontWeight="bold" color="#131722">
                          {alert.base_currency} / {alert.quote_currency}
                        </Pane>
                        <Pane color="#131722">{alert.exchange}</Pane>
                      </Pane>
                    </Table.TextCell>
                    <Table.TextCell paddingX={majorScale(1)}>
                      <Pane
                        color={
                          delta === 0
                            ? 'var(--price-gray)'
                            : delta > 0
                            ? 'var(--price-green)'
                            : 'var(--price-red)'
                        }
                      >
                        {delta.toFixed(2)}%
                      </Pane>
                    </Table.TextCell>
                    <Table.TextCell paddingX={majorScale(1)}>
                      <Pane color="#131722">{alert.price}</Pane>
                    </Table.TextCell>
                  </Table.Row>
                </Popover>
              );
            })}
        </Table.Body>
      </Table>
    </Pane>
  );
};
