import React from 'react';

interface Props {}

function Logo(props: Props) {
  return (
    <svg width={136} height={119} viewBox="0 0 136 119" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M66.272 44.228c.174.312-.933 1.636-3.348 4.005-1.984 1.946-13.53 13.012-25.657 24.59-12.126 11.58-22.253 21.14-22.504 21.245-.251.106-.548.101-.66-.01-.11-.112 8.167-14.498 18.396-31.97 10.228-17.472 18.78-31.764 19.003-31.76.223.004 3.579 3.029 7.457 6.721 3.879 3.692 7.17 6.923 7.313 7.18z"
          fill="#ABABAB"
        />
        <path
          d="M84.889 26.844c.002.126-3.569 3.67-7.935 7.875-4.366 4.206-8.161 7.596-8.433 7.533-.273-.062-3.725-3.202-7.672-6.978-3.948-3.775-7.314-7.133-7.481-7.463-.23-.453 1.63-3.9 7.62-14.126C65.347 6.245 69.12.158 69.373.158c.253 0 3.847 5.953 7.986 13.229 4.14 7.276 7.528 13.332 7.53 13.457z"
          fill="#FFF"
        />
        <path
          d="M123.092 93.556c.153.41.06.617-.278.617-.28 0-12.102-11.064-26.27-24.586C82.373 56.064 70.78 44.81 70.78 44.578c0-.232 3.412-3.693 7.583-7.692 4.17-4 7.74-7.271 7.932-7.271.193 0 .734.595 1.204 1.323.469.727 8.617 14.975 18.107 31.662 9.49 16.686 17.358 30.616 17.485 30.956z"
          fill="#5D5D5D"
        />
        <path
          d="M89.92 117.622c-25.564-.144-89.319.562-89.427 0-.064-.333 1.89-4.47 3.722-7.604 3.33-5.698 3.33-5.698 33.6-34.584 16.648-15.887 30.52-28.884 30.824-28.881.305.002 14.049 12.884 30.54 28.626 29.987 28.62 29.987 28.62 33.603 34.91 1.989 3.46 3.218 7.12 3.218 7.533 0 .753.398.262-46.08 0z"
          fill="#2C2C2C"
        />
      </g>
    </svg>
  );
}

export { Logo };
