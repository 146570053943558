import React from 'react';
import { ApiExchange } from '../api';

const initial = {
  exchanges: {},
};

interface CryptoContext {
  exchanges: { [symbol: string]: ApiExchange };
}

export const CryptoContext = React.createContext<CryptoContext>(initial);
