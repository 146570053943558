import React from 'react';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';
import './index.css';
import './App.scss';
import { App } from './App';
import sortBy from 'lodash/sortBy';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ExchangesApi, MarketsApi, ApiExchange, ApiMarket } from './api';

let authOptions = {
  userPoolId: 'us-east-1_DKi5hrnhN',
  userPoolWebClientId: '26stpkhjatmk090d55oqukiu8e',
};

Auth.configure(authOptions);

const getExchanges = async (): Promise<ApiExchange[]> => {
  return await new ExchangesApi().getExchanges();
};

const getMarkets = async (): Promise<ApiMarket[]> => {
  return await new MarketsApi().getMarkets();
};

const getUser = async (): Promise<CognitoUser> => {
  let user: any = null;
  try {
    user = await Auth.currentAuthenticatedUser();
  } catch (e) {
    user = false;
  }
  return user;
};

const initialize = async () => {
  return Promise.all([getExchanges(), getMarkets(), getUser()]);
};

initialize().then(([exchanges, markets, user]) => {
  const sortedMarkets = sortBy(markets, (m) => m.base_currency);
  const loading = document.querySelector('.pre-loading-screen');

  setTimeout(() => {
    if (loading) {
      document.body.removeChild(loading);
    }

    ReactDOM.render(
      <App exchanges={exchanges} markets={sortedMarkets} user={user} />,
      document.getElementById('root')
    );
  }, 800);
});

// ReactDOM.render(
//   <App exchanges={[]} markets={[]} />,
//   document.getElementById('root')
// );
