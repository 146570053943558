import {Auth} from 'aws-amplify';
import {ApiMarket, EventType, FavoritesApi, ServerMessage} from "../api";
import WebSocketObserver from "../socket/WebSocketObserver";

class FavoritesStore implements WebSocketObserver {

    private _favorites?: Array<ApiMarket>;
    private callbacks: Set<(favorites: Array<ApiMarket>) => void> = new Set();

    get favorites(): Array<ApiMarket> {
        return this._favorites || [];
    }

    set favorites(value: Array<ApiMarket>) {
        this._favorites = value;
        for (const callback of this.callbacks) {
            callback(this._favorites);
        }
    }

    public isFavorite(market: ApiMarket): boolean {
        for (const favorite of this.favorites) {
            if (market.market_id === favorite.market_id) {
                return true;
            }
        }
        return false;
    }

    addCallback(callback: (favorites: Array<ApiMarket>) => void) {
        this.callbacks.add(callback);
    }

    removeCallback(callback: (favorites: Array<ApiMarket>) => void) {
        this.callbacks.delete(callback);
    }

    onOpen(event: Event) {
        Auth.currentSession().then(session => {
            const favoritesApi = new FavoritesApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            favoritesApi.getFavorites().then(favorites => {
                this.favorites = favorites;
            });
        });
    }

    onMessage(message: ServerMessage) {
        if (message.event === EventType.CREATE_FAVORITE) {
            const market: ApiMarket = message.event_data;
            this.favorites = this.favorites.concat(market);
        } else if (message.event === EventType.REMOVE_FAVORITE) {
            const market: ApiMarket = message.event_data;
            this.favorites = this.favorites.filter(favorite => favorite.market_id != market.market_id);
        }
    }

    onClose (event: CloseEvent) {

    }

}

export default FavoritesStore;
