import {
  ApiCandlestickInterval,
  ApiOrderBook,
  ApiTicker,
  ApiTrade,
  EventType,
  ServerMessage,
  MessageType,
  ClientMessage,
} from '../api';
import { Subscription } from './subscription';

export class PublicSocket {
  private publicSocket?: WebSocket;
  private subscriptions = new Map<string, Subscription>();

  constructor() {
    this.connect();
    this.ping();
  }

  private ping = () => {
    if (this.publicSocket && this.publicSocket.readyState === WebSocket.OPEN) {
      this.sendMessage({
        type: MessageType.PING,
      });
    }
    setTimeout(this.ping, 60000);
  };

  public subscribeBook(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: ApiOrderBook) => any
  ) {
    this.subscribe(
      EventType.BOOK,
      exchange,
      base_currency,
      quote_currency,
      callback
    );
  }

  public subscribeTicker(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: ApiTicker) => any
  ) {
    this.subscribe(
      EventType.TICKER,
      exchange,
      base_currency,
      quote_currency,
      callback
    );
  }

  public subscribeTrades(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: ApiTrade) => any
  ) {
    this.subscribe(
      EventType.TRADE,
      exchange,
      base_currency,
      quote_currency,
      callback
    );
  }

  public subscribeCandles(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    interval: ApiCandlestickInterval,
    callback: (message: Array<any>) => any
  ) {
    switch (interval) {
      case ApiCandlestickInterval._1M:
        this.subscribe(
          EventType.CANDLE1M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._3M:
        this.subscribe(
          EventType.CANDLE3M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._5M:
        this.subscribe(
          EventType.CANDLE5M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._30M:
        this.subscribe(
          EventType.CANDLE30M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._1H:
        this.subscribe(
          EventType.CANDLE1H,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._1D:
        this.subscribe(
          EventType.CANDLE1D,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
    }
  }

  private subscribe(
    event: EventType,
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: any) => any
  ) {
    const key =
      event + '/' + exchange + '/' + base_currency + '/' + quote_currency;
    let subscription = this.subscriptions.get(key);
    if (subscription === undefined) {
      subscription = new Subscription(
        event,
        exchange,
        base_currency,
        quote_currency
      );
      this.subscriptions.set(key, subscription);
    }
    subscription.addCallback(callback);
    this.send(
      MessageType.SUBSCRIBE,
      event,
      exchange,
      base_currency,
      quote_currency
    );
  }

  public unsubscribeBook(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: ApiOrderBook) => any
  ) {
    this.unsubscribe(
      EventType.BOOK,
      exchange,
      base_currency,
      quote_currency,
      callback
    );
  }

  public unsubscribeTicker(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: ApiTicker) => any
  ) {
    this.unsubscribe(
      EventType.TICKER,
      exchange,
      base_currency,
      quote_currency,
      callback
    );
  }

  public unsubscribeTrades(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: ApiTrade) => any
  ) {
    this.unsubscribe(
      EventType.TRADE,
      exchange,
      base_currency,
      quote_currency,
      callback
    );
  }

  public unsubscribeCandles(
    exchange: string,
    base_currency: string,
    quote_currency: string,
    interval: ApiCandlestickInterval,
    callback: (message: Array<any>) => any
  ) {
    switch (interval) {
      case ApiCandlestickInterval._1M:
        this.unsubscribe(
          EventType.CANDLE1M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._3M:
        this.unsubscribe(
          EventType.CANDLE3M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._5M:
        this.unsubscribe(
          EventType.CANDLE5M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._30M:
        this.unsubscribe(
          EventType.CANDLE30M,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._1H:
        this.unsubscribe(
          EventType.CANDLE1H,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
      case ApiCandlestickInterval._1D:
        this.unsubscribe(
          EventType.CANDLE1D,
          exchange,
          base_currency,
          quote_currency,
          callback
        );
        break;
    }
  }

  private unsubscribe(
    event: EventType,
    exchange: string,
    base_currency: string,
    quote_currency: string,
    callback: (message: any) => any
  ) {
    const key =
      event + '/' + exchange + '/' + base_currency + '/' + quote_currency;
    let subscription = this.subscriptions.get(key);
    if (subscription) {
      subscription.removeCallback(callback);
      if (subscription.isEmpty()) {
        this.send(
          MessageType.UNSUBSCRIBE,
          event,
          exchange,
          base_currency,
          quote_currency
        );
      }
    }
  }

  private connect = () => {
    this.publicSocket = new WebSocket('wss://socket.aobot.io/v1/public');
    this.publicSocket.onopen = this.onOpen;
    this.publicSocket.onmessage = this.onMessage;
    this.publicSocket.onclose = this.onClose;
    this.publicSocket.onerror = this.onError;
  };

  private reconnect = () => {
    if (this.publicSocket != null) {
      this.publicSocket.onopen = null;
      this.publicSocket.onmessage = null;
      this.publicSocket.onclose = null;
      this.publicSocket.onerror = null;
    }
    this.connect();
  };

  private onOpen = (event?: Event) => {
    console.log('public socket onOpen');
    for (const subscription of this.subscriptions.values()) {
      this.send(
        MessageType.SUBSCRIBE,
        subscription.event,
        subscription.exchange,
        subscription.base_currency,
        subscription.quote_currency
      );
    }
  };

  private onMessage = (event: MessageEvent) => {
    const socketMessage: ServerMessage = JSON.parse(event.data);
    const key =
      socketMessage.event +
      '/' +
      socketMessage.exchange +
      '/' +
      socketMessage.base_currency +
      '/' +
      socketMessage.quote_currency;
    const subscription = this.subscriptions.get(key);
    if (subscription) {
      subscription.publish(socketMessage.event_data);
    }
  };

  private onClose = (event: Event) => {
    console.log('public socket onClose', event);
    setTimeout(this.reconnect, 5000);
  };

  private onError = (event: Event) => {
    console.log('public socket onError', event);
    setTimeout(this.reconnect, 5000);
  };

  private send(
    type: MessageType,
    event: EventType,
    exchange: string,
    base_currency: string,
    quote_currency: string
  ) {
    this.sendMessage({
      type: type,
      event: event,
      exchange: exchange,
      base_currency: base_currency,
      quote_currency: quote_currency,
    });
  }

  private sendMessage(message: ClientMessage) {
    if (this.publicSocket && this.publicSocket.readyState === WebSocket.OPEN) {
      const json = JSON.stringify(message);
      console.log(json);
      this.publicSocket.send(json);
    }
  }
}
