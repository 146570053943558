import React, { useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import { ApiExchange, ApiMarket } from './api';
import { AuthContext } from './context/AuthContext';
import { CryptoContext } from './context/CryptoContext';
import { AccountContext } from './context/AccountContext';
import { SocketContext } from './context/SocketContext';
import { Main } from './Main';
import { LoginScreen } from './components/LoginScreen';
import UserSocket from './socket/UserSocket';
import { PublicSocket } from './socket/publicSocket';

interface AppProps {
  exchanges: Array<ApiExchange>;
  markets: Array<ApiMarket>;
  user: CognitoUser;
}

enum Screens {
  Login = 0,
  Register = 1,
}

export const App: React.FC<AppProps> = (props) => {
  const [screen, setScreen] = useState<Screens | null>(null);
  const [user, setUser] = useState<CognitoUser | null>(props.user);

  const toggleScreen = (screen: Screens) => setScreen(screen);

  const handleLoginSuccess = (user: CognitoUser) => {
    setUser(user);
    setScreen(null);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    setScreen(null);
    setUser(null);
  };

  const closeScreen = () => {
    setScreen(null);
  };

  (window as any)._props = props;

  return (
    <AuthContext.Provider
      value={{
        user,
        auth: Auth,
        handleLogin: () => toggleScreen(Screens.Login),
        handleRegister: () => toggleScreen(Screens.Register),
        handleLogout,
      }}
    >
      <CryptoContext.Provider
        value={{
          exchanges: props.exchanges.reduce(
            (acc, curr) => ({ ...acc, [curr.symbol]: curr }),
            {}
          ),
        }}
      >
        <AccountContext.Provider
          value={{
            accounts: {},
          }}
        >
          <SocketContext.Provider
            value={{
              userSocket: new UserSocket(),
              publicSocket: new PublicSocket(),
            }}
          >
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Cryptobot</title>
              </Helmet>
              <LoginScreen
                isOpen={screen === Screens.Login}
                onClose={closeScreen}
                onSuccess={handleLoginSuccess}
              />
              <Main {...props} />
            </>
          </SocketContext.Provider>
        </AccountContext.Provider>
      </CryptoContext.Provider>
    </AuthContext.Provider>
  );
};
