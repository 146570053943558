import { Auth } from 'aws-amplify';
import {
  Button,
  Icon,
  IconButton,
  majorScale,
  minorScale,
  Pane,
  Position,
  Tooltip,
  toaster,
  SegmentedControl,
} from 'evergreen-ui';
import throttle from 'lodash/throttle';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  AccountsApi,
  ApiAccount,
  ApiMarket,
  MarketsApi,
  AlertsApi,
  ApiAlert,
  ServerMessage,
  EventType,
  ApiTicker,
} from './api';
import { AccountsTab } from './components/AccountsTab';
import { AlertsTab } from './components/AlertsTab';
import { CryptoTable } from './components/CryptoTable';
import { TradingViewChart } from './components/TradingViewChart';
import { CrossHairMovedEventParams } from './tradingview/charting_library/charting_library.min';
import { SocketContext } from './context/SocketContext';
import { AuthContext } from './context/AuthContext';
import { ChartBottomTabButton } from './components/ChartBottomTabButton';
import { BitfinexChart } from './components/BitfinexChart';

interface ChartProps {
  markets: Array<ApiMarket>;
}

enum SideScreens {
  None = 'None',
  Watchlist = 'Watchlist',
  Alerts = 'Alerts',
  AsyncOrderBook = 'AsyncOrderBook',
  TradeHistory = 'TradeHistory',
}

const SideScreenSizes = {
  [SideScreens.None]: 0,
  [SideScreens.Watchlist]: 250,
  [SideScreens.Alerts]: 300,
  [SideScreens.AsyncOrderBook]: 275,
  [SideScreens.TradeHistory]: 275,
};

export enum BottomTabs {
  Crypto = 'Crypto',
  Orders = 'Orders',
  Alerts = 'Alerts',
  Accounts = 'Accounts',
}

const AsyncOrderBook = React.lazy(() =>
  import('./components/OrderBook').then(({ OrderBook }) => ({
    default: OrderBook,
  }))
);

export const Chart: React.FC<ChartProps> = (props) => {
  const [accountsBySymbol, setAccountsBySymbol] = useState<{
    [k: string]: ApiAccount[];
  }>({});
  const [chartType, setChartType] = useState<string>('TRADINGVIEW');
  const [orderBook, setOrderBook] = useState<Boolean>(false);
  const [alertsTab, setAlertsTab] = useState<Boolean>(false);
  const [alerts, setAlerts] = useState<ApiAlert[]>([]);
  const [bottomTab, setBottomTab] = useState<string>(BottomTabs.Crypto);
  const [isResizing, setResizing] = useState<boolean>(false);
  const [ticker, setTicker] = useState<ApiTicker>();
  const [crosshairValue, setCrosshairValue] = useState<number>();
  const { exchange, base, quote } = useParams();
  const { userSocket, publicSocket } = useContext(SocketContext);
  const { handleLogin } = useContext(AuthContext);

  let history = useHistory();

  if (!exchange || !base || !quote) {
    throw new Error('fdsafsa');
  }

  const loadAlerts = () => {
    Auth.currentSession().then((session) => {
      const alertsApi = new AlertsApi({
        accessToken: session.getAccessToken().getJwtToken(),
      });
      alertsApi.getAlerts().then((alerts) => {
        setAlerts(alerts.filter((a) => a.status !== 'CANCELLED'));
      });
    });
  };

  const deleteAlert = (alert: ApiAlert) => {
    setAlerts((alerts) => alerts.filter((a) => a.alert_id !== alert.alert_id));
    Auth.currentSession().then((session) => {
      const alertsApi = new AlertsApi({
        accessToken: session.getAccessToken().getJwtToken(),
      });
      alertsApi
        .deleteAlert(alert.alert_id)
        .finally(() => {
          toaster.success(`Alert removed`, {
            description: `${alert.base_currency} / ${alert.quote_currency} @ ${alert.price}`,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  useEffect(() => {
    let getBookAsync = async () => {
      let api = new MarketsApi();
      let response = await api.getBook(exchange, base, quote);
      console.log(response);
    };

    let getAccounts = async () => {
      Auth.currentSession().then((session) => {
        const accountsApi = new AccountsApi({
          accessToken: session.getAccessToken().getJwtToken(),
        });
        accountsApi.getAccounts().then((accounts) => {
          console.log(accounts);
          setAccountsBySymbol(
            accounts.reduce<{ [k: string]: ApiAccount[] }>((acc, curr) => {
              if (acc.hasOwnProperty(curr.exchange)) {
                acc[curr.exchange].concat(curr);
              } else {
                acc[curr.exchange] = [curr];
              }
              return {
                ...acc,
              };
            }, {})
          );
        });
      });
    };

    const getTicker = async () => {
      Auth.currentSession().then((session) => {
        const marketsApi = new MarketsApi({
          accessToken: session.getAccessToken().getJwtToken(),
        });
        marketsApi.getTicker(exchange, base, quote).then((response) => {
          setTicker(response);
        });
      });
    };

    const handleTickerUpdate = (ticker: ApiTicker) => {
      setTicker(ticker);
    };

    publicSocket!.subscribeTicker(exchange, base, quote, handleTickerUpdate);

    userSocket!.addObserver({
      onOpen: () => {},
      onClose: () => {},
      onMessage: (message: ServerMessage) => {
        if (message.event === EventType.CREATE_ALERT) {
          setAlerts((alerts) => alerts.concat(message.event_data));
        }

        if (message.event === EventType.DELETE_ALERT) {
          setAlerts((alerts) =>
            alerts.filter((a) => a.alert_id !== message.event_data.alert_id)
          );
        }
      },
    });

    getBookAsync();
    getAccounts();
    loadAlerts();
    getTicker();

    return () => {
      publicSocket!.unsubscribeTicker(
        exchange,
        base,
        quote,
        handleTickerUpdate
      );
    };
  }, [exchange, quote, base]);

  const addAlert = useCallback(
    (price: number) => {
      Auth.currentSession()
        .then((session) => {
          const alertsApi = new AlertsApi({
            accessToken: session.getAccessToken().getJwtToken(),
          });
          alertsApi
            .addAlert({
              exchange,
              price: String(price),
              base_currency: base,
              quote_currency: quote,
            })
            .then(() => {
              toaster.success(`Alert added`, {
                description: `${base} / ${quote} @ ${String(price)}`,
              });
            });
        })
        .catch(() => {
          handleLogin();
        });
    },
    [exchange, base, quote]
  );

  let hasInvalidAccount = false;
  for (let a in accountsBySymbol) {
    let accounts = accountsBySymbol[a];
    if (accounts.some((a) => a.status === 'INVALID')) {
      hasInvalidAccount = true;
      break;
    }
  }

  let minSizeRightPanel = 0;
  if (orderBook) {
    minSizeRightPanel += SideScreenSizes[SideScreens.AsyncOrderBook];
  }
  if (alertsTab) {
    minSizeRightPanel += SideScreenSizes[SideScreens.Alerts];
  }

  let changePercentage = 0;
  if (ticker) {
    changePercentage =
      ((Number(ticker.last) - Number(ticker.open_24h)) * 100) /
      Number(ticker.open_24h);
  }

  return (
    <>
      <Helmet defer={false}>
        <title>{`${base} / ${quote} ${
          ticker?.last ? Number(ticker.last).toString() : '' // removes insignifcant zeroes
        } ${changePercentage > 0 ? '▲' : '▼'} ${changePercentage.toFixed(
          2
        )}%`}</title>
      </Helmet>
      <Pane
        display="flex"
        flex="auto"
        height="100%"
        width="100%"
        // maxHeight="calc(100vh)"
        overflow="hidden"
      >
        <Pane
          display="flex"
          flexDirection="column"
          flex="auto"
          height="100%"
          position="relative"
        >
          <SplitPane
            split="vertical"
            minSize={minSizeRightPanel}
            allowResize={minSizeRightPanel !== 0 ? true : false}
            pane2Style={{
              display: minSizeRightPanel !== 0 ? 'block' : 'none',
            }}
            primary="second"
            onDragStarted={() => setResizing(true)}
            onDragFinished={() => setResizing(false)}
          >
            <Pane height="100%">
              <SplitPane
                defaultSize="60%"
                split="horizontal"
                onDragStarted={() => setResizing(true)}
                onDragFinished={() => setResizing(false)}
                pane2Style={{
                  overflow: 'hidden',
                }}
              >
                <Pane
                  display="flex"
                  flex="auto"
                  flexDirection="column"
                  height="100%"
                >
                  {/* <Draggable>
                  <Pane position="absolute">Buy \ Sell</Pane>
                </Draggable> */}
                  <Pane
                    flex="none"
                    height={30}
                    borderBottom="3px solid #E0E3EB"
                  >
                    <SegmentedControl
                      width={240}
                      options={[
                        { label: 'TradingView', value: 'TRADINGVIEW' },
                        { label: 'Bitfinex', value: 'BITFINEX' },
                      ]}
                      value={chartType}
                      onChange={(value: any) => setChartType(value)}
                    />
                  </Pane>
                  <Pane flex="auto">
                    {chartType === 'TRADINGVIEW' ? (
                      <TradingViewChart
                        exchange={exchange}
                        base={base}
                        quote={quote}
                        updateCrosshair={(value) => {
                          setCrosshairValue(value);
                        }}
                        disablePointerEvents={isResizing}
                        addAlert={addAlert}
                        deleteAlert={deleteAlert}
                        toggleAlertsTab={() => {
                          setAlertsTab(!alertsTab);
                        }}
                        alerts={alerts}
                      />
                    ) : (
                      <BitfinexChart
                        exchange={exchange}
                        base={base}
                        quote={quote}
                      />
                    )}
                  </Pane>
                </Pane>
                <Pane display="flex" flex="auto" height="100%">
                  <Pane display="flex" flexDirection="column" flex="auto">
                    <Pane
                      display="flex"
                      flex="none"
                      width="100%"
                      borderTop="3px solid #E0E3EB"
                    >
                      <ChartBottomTabButton
                        isActive={bottomTab === BottomTabs.Crypto}
                        onClick={() => setBottomTab(BottomTabs.Crypto)}
                      >
                        {BottomTabs.Crypto}
                      </ChartBottomTabButton>
                      <ChartBottomTabButton
                        isActive={bottomTab === BottomTabs.Accounts}
                        onClick={() => setBottomTab(BottomTabs.Accounts)}
                      >
                        <Pane display="flex" alignItems="center">
                          {BottomTabs.Accounts}
                          {hasInvalidAccount && (
                            <Icon
                              icon="error"
                              color="danger"
                              marginLeft={majorScale(1)}
                            />
                          )}
                        </Pane>
                      </ChartBottomTabButton>
                      <Pane
                        marginLeft="auto"
                        flex="auto"
                        borderBottom="1px solid #E0E3EB"
                      ></Pane>
                    </Pane>
                    <Pane
                      className="test"
                      flex="auto"
                      height="100%"
                      overflow="hidden"
                    >
                      {bottomTab === BottomTabs.Crypto && (
                        <CryptoTable
                          markets={props.markets}
                          handleRowClick={(market: ApiMarket) => {
                            history.push(
                              `/chart/${market.exchange}/${market.base_currency}/${market.quote_currency}`
                            );
                          }}
                        />
                      )}
                      {bottomTab === BottomTabs.Accounts && (
                        <AccountsTab accounts={accountsBySymbol} />
                      )}
                    </Pane>
                  </Pane>
                </Pane>
              </SplitPane>
            </Pane>
            <Pane height="100%" width="100%">
              <Pane
                display="flex"
                width="100%"
                height="100%"
                overflowX="hidden"
              >
                {orderBook && (
                  <Pane
                    width="100%"
                    height="100%"
                    display={orderBook ? 'block' : 'none'}
                    borderRight="1px solid #DADDE0"
                    position="relative"
                    overflow="hidden"
                  >
                    <Scrollbars style={{ height: '100%' }} autoHide>
                      <Suspense fallback="Loading">
                        <AsyncOrderBook
                          exchange={exchange}
                          base={base}
                          quote={quote}
                        />
                      </Suspense>
                    </Scrollbars>
                  </Pane>
                )}
                {alertsTab && (
                  <Pane
                    width="100%"
                    height="100%"
                    display={alerts ? 'block' : 'none'}
                    borderRight="1px solid #DADDE0"
                    position="relative"
                  >
                    <Scrollbars style={{ height: '100%' }} autoHide>
                      <AlertsTab
                        alerts={alerts}
                        addAlert={addAlert}
                        deleteAlert={deleteAlert}
                        base={base}
                        quote={quote}
                        exchange={exchange}
                        ticker={ticker}
                        crosshairValue={crosshairValue}
                      />
                    </Scrollbars>
                  </Pane>
                )}
              </Pane>
            </Pane>
          </SplitPane>
        </Pane>
        <Pane
          width={50}
          display="flex"
          justifyContent="center"
          flex="none"
          paddingY={majorScale(1)}
          backgroundColor="#F0F3FA"
        >
          <Pane>
            {/* <Tooltip content="Alerts" position={Position.LEFT}>
              <IconButton
                appearance="minimal"
                icon="notifications"
                iconSize={18}
                marginBottom={majorScale(2)}
                onClick={() => setAlerts(!alerts)}
              />
            </Tooltip> */}
            <Tooltip content="Order Book" position={Position.LEFT}>
              <IconButton
                appearance="minimal"
                icon="vertical-bar-chart-asc"
                iconSize={18}
                marginBottom={majorScale(2)}
                onClick={() => setOrderBook(!orderBook)}
              />
            </Tooltip>
            <Tooltip content="Alerts" position={Position.LEFT}>
              <IconButton
                appearance="minimal"
                icon="notifications"
                iconSize={18}
                marginBottom={majorScale(2)}
                onClick={() => setAlertsTab(!alertsTab)}
              />
            </Tooltip>
          </Pane>
        </Pane>
      </Pane>
    </>
  );
};
