import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  ChartingLibraryWidgetOptions,
  CrossHairMovedEventParams,
  IChartingLibraryWidget,
  IChartWidgetApi,
  widget,
  IPositionLineAdapter,
} from '../tradingview/charting_library/charting_library.min';
import { TradingViewDataFeed } from '../tradingview/TradingViewDataFeed';
import { ApiAlert } from '../api';
import { SocketContext } from '../context/SocketContext';

type crossHairEvent = {
  time: number;
  price: number;
};

interface TradingViewChartProps {
  exchange: string;
  base: string;
  quote: string;
  disablePointerEvents: boolean;
  updateCrosshair: Dispatch<SetStateAction<number | undefined>>;
  addAlert: (price: number) => void;
  alerts: ApiAlert[];
  deleteAlert: (alert: ApiAlert) => void;
  toggleAlertsTab: () => void;
}

export const TradingViewChart: React.FC<TradingViewChartProps> = React.memo(
  ({
    exchange,
    base,
    quote,
    updateCrosshair,
    disablePointerEvents,
    toggleAlertsTab,
    alerts,
  }) => {
    const chartWidget = useRef<IChartingLibraryWidget>();
    const chart = useRef<IChartWidgetApi>();
    const [lines, setLines] = useState<IPositionLineAdapter[]>([]);
    const { publicSocket } = useContext(SocketContext);

    useEffect(() => {
      const options: ChartingLibraryWidgetOptions = {
        symbol: `${exchange}: ${base}-${quote}`,
        datafeed: new TradingViewDataFeed({
          base: base,
          quote: quote,
          exchange: exchange,
          exchanges: [exchange],
          publicSocket: publicSocket!,
        }),
        interval: '60',
        container_id: 'tv_chart_container',
        library_path: '/charting_library/',
        // debug: true,
        locale: 'en',
        disabled_features: [
          'use_localstorage_for_settings',
          'left_toolbar',
          'go_to_date',
          'header_saveload',
          'header_compare',
          'header_symbol_search',
          'remove_library_container_border',
        ],
        enabled_features: ['chart_crosshair_menu'],
        charts_storage_url: 'https://saveload.tradingview.com',
        charts_storage_api_version: '1.1',
        client_id: 'tradingview.com',
        user_id: 'public_user_id',
        autosize: true,
        studies_overrides: {},
        custom_css_url: 'styles.css', // defined in static/styles.css
        // theme: 'Dark',
      };

      chartWidget.current = new widget(options);

      let tvWidget = chartWidget.current;

      tvWidget.onChartReady(() => {
        let lastPrice: number;

        if (tvWidget) {
          (window as any).b = tvWidget;
          chart.current = tvWidget.chart();
          chart.current.crossHairMoved((a: CrossHairMovedEventParams) => {
            lastPrice = a.price;
          });
          tvWidget.subscribe('mouse_up', () => {
            updateCrosshair(lastPrice);
          });
        }

        // tvWidget.onContextMenu((unixtime, price) => {
        //   return [
        //     {
        //       position: 'top',
        //       text: `Add alert for ${price}`,
        //       click: () => {
        //         addAlert(price);
        //       },
        //     },
        //   ];
        // });

        // draws a position line
        // tvWidget
        //   .chart()
        //   .createPositionLine({})
        //   .onModify(function() {
        //     // this.setText('onModify called');
        //   })
        //   .onReverse('onReverse called', function(text) {
        //     // this.setText(text);
        //   })
        //   .onClose('onClose called', function(text) {
        //     // this.setText(text);
        //   })
        //   .setText('PROFIT: 71.1 (3.31%)')
        //   .setTooltip('Additional position information')
        //   .setProtectTooltip('Protect position')
        //   .setCloseTooltip('Close position')
        //   .setReverseTooltip('Reverse position')
        //   .setQuantity('8.235')
        //   .setPrice(5450)
        //   .setExtendLeft(false)
        //   .setLineStyle(0)
        //   .setLineLength(25);

        //   return [
        //     {
        //       position: 'top',
        //       text:
        //         'First top menu item, time: ' + unixtime + ', price: ' + price,
        //       click: function() {
        //         alert('First clicked.');
        //       },
        //     },
        //     { text: '-', position: 'top' },
        //     { text: '-Objects Tree...' },
        //     {
        //       position: 'top',
        //       text: 'Second top menu item 2',
        //       click: function() {
        //         alert('Second clicked.');
        //       },
        //     },
        //     {
        //       position: 'bottom',
        //       text: 'Bottom menu item',
        //       click: function() {
        //         alert('Third clicked.');
        //       },
        //     },
        //   ];
        // });

        tvWidget.headerReady().then(() => {
          const button = tvWidget.createButton();
          button.setAttribute('title', 'Click to open alerts panel');
          button.classList.add('tv-chart-button');
          button.addEventListener('click', () => toggleAlertsTab());
          button.innerHTML = 'Alerts';
        });
      });
    }, [exchange, base, quote]);

    useEffect(() => {
      let tvWidget = chartWidget.current!;

      // remove all lines
      lines.map((line) => line.remove());

      tvWidget.onChartReady(() => {
        let drawnLines: IPositionLineAdapter[] = [];

        alerts
          .filter((alert) => {
            return (
              alert.exchange === exchange &&
              alert.base_currency === base &&
              alert.quote_currency === quote
            );
          })
          .forEach((alert) => {
            const line = tvWidget
              .chart()
              .createPositionLine({})
              .onModify(function () {
                // this.setText('onModify called');
              })
              // .onClose('onClose called', function(this: IPositionLineAdapter) {
              // deleteAlert(alert);
              // })
              .setText('')
              // .setCloseTooltip('Remove alert')
              .setQuantity('')
              .setLineLength(0)
              .setPrice(Number(alert.price));
            drawnLines = drawnLines.concat(line);
          });
        setLines(drawnLines);
      });

      return () => {
        setLines([]);
      };
    }, [alerts]);
    return (
      <div
        id="tv_chart_container"
        className={'TVChartContainer'}
        style={{
          height: '100%',
          width: '100%',
          pointerEvents: disablePointerEvents ? 'none' : 'all',
        }}
      />
    );
  }
);
