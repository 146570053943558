import React, { useState, useEffect } from 'react';
import BFXChart from 'bfx-hf-chart';
import { MarketsApi, ApiCandlestickInterval } from '../api';
import { AutoSizer } from 'react-virtualized';

interface Candle extends Array<number> {
  0: number;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
}

interface ChartProps {
  exchange: string;
  base: string;
  quote: string;
}

// {
//   candles: Array<Candle>;
//   candleWidth: string;
//   width: number;
//   height: number;
// }

let today = new Date();
let fiveDaysAgo = new Date();
fiveDaysAgo.setTime(fiveDaysAgo.getTime() - 24 * 60 * 60 * 1000 * 5);

export const BitfinexChart = (props: ChartProps) => {
  const [candles, setCandles] = useState<any>([]);
  useEffect(() => {
    const getBars = async () => {
      let data = await new MarketsApi().getCandles(
        props.exchange,
        props.base,
        props.quote,
        ApiCandlestickInterval._1M,
        fiveDaysAgo.getTime(),
        today.getTime()
      );

      let x = data.map((d) => {
        return [
          d[0],
          parseFloat(d[1]),
          parseFloat(d[4]),
          parseFloat(d[2]),
          parseFloat(d[3]),
          parseFloat(d[5]),
        ];
      });

      setCandles(x);
    };
    getBars();
  }, []);

  if (!candles.length) {
    return <div>Loading Candles</div>;
  }

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <BFXChart
            candles={candles}
            candleWidth="1m"
            width={width}
            height={height}
          />
        );
      }}
    </AutoSizer>
  );
};
