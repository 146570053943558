import React, { useState, useContext } from 'react';
import { Pane, majorScale, Icon } from 'evergreen-ui';
import { CryptoContext } from '../context/CryptoContext';
import { AuthContext } from '../context/AuthContext';
import { ApiExchange, ApiAccount } from '../api';
import { ExchangeIcon } from './ExchangeIcon';
import { BrokerSetupScreen } from './BrokerSetupScreen';
import pluralize from 'pluralize';

export interface IAccountsProps {
  accounts: { [k: string]: ApiAccount[] };
}

export function AccountsTab(props: IAccountsProps) {
  const { user, handleLogin } = useContext(AuthContext);
  const [exchangeToSetup, setExchangeToSetup] = useState<ApiExchange | null>(
    null
  );
  const [setupOpen, setSetupOpen] = useState(false);
  const { exchanges } = useContext(CryptoContext);
  const handleClick = (exchange: ApiExchange) => {
    if (!user) {
      handleLogin();
      return;
    }
    setExchangeToSetup(exchange);
    setSetupOpen(true);
  };

  return (
    <Pane
      display="flex"
      justifyContent="center"
      height="100%"
      paddingTop={majorScale(3)}
      userSelect="none"
    >
      {exchangeToSetup && (
        <BrokerSetupScreen
          accounts={props.accounts}
          exchange={exchangeToSetup}
          isOpen={setupOpen}
          onClose={() => setSetupOpen(false)}
          onSuccess={() => {}}
        />
      )}

      <Pane textAlign="center">
        <Pane color="var(--black)" fontSize={24} marginBottom={majorScale(2)}>
          Start trading
        </Pane>
        <Pane color="var(--black)" fontSize={16} marginBottom={majorScale(3)}>
          Connect your accounts
        </Pane>
        <Pane
          className="accounts-list"
          display="flex"
          justifyContent="center"
          flex="auto"
          flexWrap="wrap"
        >
          {Object.values(exchanges).map((exchange: ApiExchange) => {
            const hasAccounts = props.accounts.hasOwnProperty(exchange.symbol);
            const hasInvalid = hasAccounts
              ? props.accounts[exchange.symbol].some(
                  (e) => e.status === 'INVALID'
                )
              : false;
            return (
              <Pane
                className="broker-item"
                key={exchange.symbol}
                display="flex"
                flex="0 0 auto"
                flexDirection="column"
                alignItems="center"
                position="relative"
                borderRadius={6}
                width={150}
                paddingY={majorScale(4)}
                textAlign="center"
                margin={majorScale(1)}
                elevation={1}
                onClick={() => handleClick(exchange)}
              >
                <ExchangeIcon
                  symbol={exchange.symbol}
                  size={32}
                  marginBottom={majorScale(2)}
                />
                <Pane fontSize={16} marginBottom={majorScale(1)}>
                  {exchange.name}
                </Pane>
                <Pane fontSize={12} color="rgba(49, 54, 57, 0.5)">
                  <Pane display="flex" alignItems="center">
                    <Pane display="flex" alignItems="center">
                      {hasAccounts ? (
                        hasInvalid ? (
                          `${
                            props.accounts[exchange.symbol].length
                          } ${pluralize(
                            'account',
                            props.accounts[exchange.symbol].length
                          )}`
                        ) : (
                          <Pane display="flex" alignItems="center">
                            <Icon
                              icon="tick-circle"
                              color="success"
                              marginRight={majorScale(1)}
                            />
                            {`${
                              props.accounts[exchange.symbol].length
                            } ${pluralize(
                              'account',
                              props.accounts[exchange.symbol].length
                            )}`}
                          </Pane>
                        )
                      ) : (
                        'Connect'
                      )}
                    </Pane>
                    {hasInvalid && (
                      <Icon
                        marginLeft={majorScale(1)}
                        icon="error"
                        color="danger"
                      />
                    )}
                  </Pane>
                </Pane>
              </Pane>
            );
          })}
        </Pane>
      </Pane>
    </Pane>
  );
}
