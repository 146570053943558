import React, { useState } from 'react';
import { Pane, PaneProps } from 'evergreen-ui';

interface Props extends PaneProps {
  symbol: string;
  size?: number;
  style?: any;
}

const ExchangeIcon = ({
  symbol = '',
  size = 24,
  style = {},
  ...props
}: Props) => {
  const [image, setImage] = useState(
    `https://content.aobot.io/icons/exchange/128/${symbol.toLowerCase()}.png`
  );
  return (
    <Pane
      is="img"
      height={size}
      width={size}
      onError={() => {
        setImage(`https://content.aobot.io/icons/svg/color/generic.svg`);
      }}
      alt={symbol}
      src={image}
      {...props}
    />
  );
};

export { ExchangeIcon };
