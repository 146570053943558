import React, { useState, useRef, useMemo, useContext } from 'react';
import {
  Pane,
  Autocomplete,
  TextInput,
  Button,
  majorScale,
  Icon,
  AutocompleteItem,
  AutocompleteItemProps,
} from 'evergreen-ui';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { ApiMarket } from '../api';
import { ExchangeIcon } from './ExchangeIcon';
import { CryptoContext } from '../context/CryptoContext';

export interface ITickerSearchProps extends RouteComponentProps {
  markets: Array<ApiMarket>;
}

export const TickerSearch = withRouter((props: ITickerSearchProps) => {
  const commands = useRef<any>();
  const marketsByMarketId = useMemo(() => {
    return props.markets.reduce<{ [k: string]: ApiMarket }>(
      (acc, curr) => ({
        ...acc,
        [curr.market_id]: curr,
      }),
      {}
    );
  }, [props.markets]);

  const sortedMarketsByExchange = useMemo(() => {
    return sortBy(props.markets, (m) => m.exchange);
  }, [props.markets]);

  const { exchanges } = useContext(CryptoContext);

  return (
    <Pane>
      <Autocomplete
        onChange={(market: ApiMarket) => {
          if (!market) {
            return;
          }

          props.history.push(
            `/chart/${market.exchange}/${market.base_currency}/${market.quote_currency}`
          );

          commands!.current.clearSelection();
          commands!.current.reset();
        }}
        items={sortedMarketsByExchange}
        itemToString={(market: ApiMarket) => {
          if (market) {
            return market.market_id;
          }
          return '';
        }}
        popoverMaxHeight={500}
        renderItem={({
          isHighlighted,
          isSelected,
          ...props
        }: AutocompleteItemProps) => {
          const market = marketsByMarketId[props.key];
          return (
            <Pane
              className={`ticker-search-item ${
                isHighlighted ? 'highlighted' : ''
              } ${isSelected ? 'selected' : ''}`}
              padding={majorScale(1)}
              {...props}
            >
              <Pane display="flex" width="100%">
                <Pane flex="none">{market.symbol}</Pane>
                <Pane display="flex" alignItems="center" marginLeft="auto">
                  <Pane color="var(--oc-gray-6)" marginRight={majorScale(1)}>
                    {exchanges[market.exchange].name}
                  </Pane>
                  <ExchangeIcon symbol={market.exchange} size={16} />
                </Pane>
              </Pane>
            </Pane>
          );
        }}
      >
        {({
          key,
          getInputProps,
          getRef,
          inputValue,
          openMenu,
          toggleMenu,
          reset,
          clearSelection,
          isShown,
          ...rest
        }: any) => {
          commands.current = {
            clearSelection,
            reset,
          };
          return (
            <Pane key={key} innerRef={getRef} display="flex">
              <Pane
                position="fixed"
                top={0}
                right={0}
                bottom={0}
                left={0}
                zIndex={9999}
                display={isShown ? 'block' : 'none'}
                onClick={() => {
                  clearSelection();
                  reset();
                }}
              />
              <Pane
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                zIndex={10000}
              >
                <TextInput
                  className="symbol-search-input"
                  placeholder="Search by Ticker..."
                  borderTopRightRadius={0}
                  borderBottomRightRadius={0}
                  zIndex={2}
                  position="relative"
                  {...getInputProps({
                    onFocus: () => {
                      openMenu();
                    },
                  })}
                />
                <Button
                  className="input-addon"
                  appearance="default"
                  position="relative"
                  left={0}
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                  zIndex={10}
                  onClick={() => {
                    clearSelection();
                    reset();
                  }}
                  padding={majorScale(1)}
                >
                  <Icon icon="cross" color="muted" />
                </Button>
              </Pane>
            </Pane>
          );
        }}
      </Autocomplete>
    </Pane>
  );
});
