import { EventType } from '../api';

export class Subscription {
  private readonly _event: EventType;
  private readonly _exchange: string;
  private readonly _base_currency: string;
  private readonly _quote_currency: string;

  private readonly callbacks = new Set<(message: any) => any>();

  constructor(
    event_type: EventType,
    exchange: string,
    base_currency: string,
    quote_currency: string
  ) {
    this._event = event_type;
    this._exchange = exchange;
    this._base_currency = base_currency;
    this._quote_currency = quote_currency;
  }

  get event(): EventType {
    return this._event;
  }

  get exchange(): string {
    return this._exchange;
  }

  get base_currency(): string {
    return this._base_currency;
  }

  get quote_currency(): string {
    return this._quote_currency;
  }

  public addCallback(callback: (message: any) => any) {
    this.callbacks.add(callback);
  }

  public removeCallback(callback: (message: any) => any) {
    this.callbacks.delete(callback);
  }

  public isEmpty(): boolean {
    return this.callbacks.size == 0;
  }

  public publish(message: any) {
    for (const callback of this.callbacks) {
      callback(message);
    }
  }
}
