import { Auth } from 'aws-amplify';
import {
  AccountsApi,
  ApiAccount,
  ApiMarket,
  EventType,
  ServerMessage,
} from '../api';
import WebSocketObserver from '../socket/WebSocketObserver';

class AccountsStore implements WebSocketObserver {
  private _accounts?: Array<ApiAccount>;
  private callbacks: Set<(accounts: Array<ApiAccount>) => void> = new Set();

  get accounts(): Array<ApiAccount> {
    return this._accounts || [];
  }

  set accounts(value: Array<ApiAccount>) {
    this._accounts = value;
    for (const callback of this.callbacks) {
      callback(this._accounts);
    }
  }

  public getAccounts(exchange: string): Array<ApiAccount> {
    return this.accounts.filter(a => a.exchange === exchange);
  }

  addCallback(callback: (favorites: Array<ApiAccount>) => void) {
    this.callbacks.add(callback);
  }

  removeCallback(callback: (favorites: Array<ApiAccount>) => void) {
    this.callbacks.delete(callback);
  }

  onOpen(event: Event) {
    Auth.currentSession().then(session => {
      const accountsApi = new AccountsApi({
        accessToken: session.getAccessToken().getJwtToken(),
      });
      accountsApi.getAccounts().then(accounts => {
        this.accounts = accounts;
      });
    });
  }

  onMessage(message: ServerMessage) {
    if (message.event === EventType.CREATE_ACCOUNT) {
      const account: ApiAccount = message.event_data;
      this.accounts = this.accounts.concat(account);
    } else if (message.event === EventType.DELETE_ACCOUNT) {
      const account: ApiAccount = message.event_data;
      this.accounts = this.accounts.filter(
        a => a.account_id != account.account_id
      );
    }
  }

  onClose(event: CloseEvent) {}
}

export default AccountsStore;
