import React, { useState, useRef, useEffect, FormEvent } from 'react';
import { Auth } from 'aws-amplify';
import { findDOMNode } from 'react-dom';
import {
  Pane,
  majorScale,
  minorScale,
  IconButton,
  Spinner,
  TextInputField,
  Alert,
} from 'evergreen-ui';
import { ApiExchange, AccountsApi, ApiAccount } from '../api';
import { ExchangeIcon } from './ExchangeIcon';

export interface IBrokerSetupScreenProps {
  exchange: ApiExchange;
  accounts: { [k: string]: ApiAccount[] };
  isOpen: boolean;
  onClose: (account: ApiAccount) => void;
  onSuccess: () => void;
  onError?: () => void;
}

export function BrokerSetupScreen(props: IBrokerSetupScreenProps) {
  const [loading, setLoading] = useState(false);
  const screenRef = useRef(null);
  const [name, setName] = useState<string>();
  const [key, setKey] = useState<string>();
  const [secret, setSecret] = useState<string>();
  const [passphrase, setPassphrase] = useState<string>();

  const hasInvalidAccount = props.accounts.hasOwnProperty(props.exchange.symbol)
    ? props.accounts[props.exchange.symbol].some(a => a.status === 'INVALID')
    : false;

  useEffect(() => {
    if (props.isOpen) {
      if (screenRef.current) {
        const el = findDOMNode(screenRef.current) as HTMLElement;
        setTimeout(() => {
          el.classList.add('is-open');
        }, 0);

        el.addEventListener('transitionend', () => {
          el.querySelector('input')!.focus();
        });
      }
    }
  }, [props.isOpen, screenRef]);

  if (!props.isOpen) {
    return null;
  }

  const requiresPassphrase = ['GDAX', 'OKFT'].includes(props.exchange.symbol);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    Auth.currentSession().then(session => {
      const accountsApi = new AccountsApi({
        accessToken: session.getAccessToken().getJwtToken(),
      });

      accountsApi
        .createAccount({
          name,
          exchange: props.exchange.symbol,
          key,
          secret,
          passphrase: requiresPassphrase ? passphrase : undefined,
        })
        .then(account => {
          console.log(account);
          props.onClose(account);
        })
        .catch(reason => {
          console.error(reason);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Pane
      className="screen"
      ref={screenRef}
      position="fixed"
      left={0}
      right={0}
      top={0}
      bottom={0}
      display="flex"
      justifyContent="center"
      paddingTop="18vh"
      role="dialog"
      aria-modal="true"
    >
      <Pane position="absolute" top={majorScale(2)} right={majorScale(2)}>
        <IconButton
          className="close-button"
          appearance="minimal"
          icon="cross"
          iconSize={36}
          onClick={props.onClose}
        />
      </Pane>
      <Pane className="screen-inner">
        <Pane textAlign="center" marginBottom={majorScale(6)}>
          <Pane marginBottom={majorScale(4)}>
            <ExchangeIcon symbol={props.exchange.symbol} size={48} />
          </Pane>
          <Pane is="h1" fontWeight={100} color="white">
            {props.exchange.name} Setup
          </Pane>
        </Pane>
        <Pane
          maxWidth={300}
          marginX="auto"
          pointerEvents={loading ? 'none' : 'all'}
          position="relative"
        >
          {hasInvalidAccount && (
            <Alert
              intent="danger"
              title="Failed to connect"
              marginBottom={majorScale(2)}
            >
              One or more of your {props.exchange.name} accounts failed to
              connect
            </Alert>
          )}
          {loading && (
            <Pane
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner className="loading" />
            </Pane>
          )}
          <Pane is="form" onSubmit={handleSubmit} opacity={loading ? 0.3 : 1}>
            <Pane marginBottom={majorScale(6)}>
              <TextInputField
                isInvalid={false}
                label="Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
                autoFocus
              />
              <TextInputField
                isInvalid={false}
                label="Key"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setKey(e.target.value)
                }
              />
              <TextInputField
                isInvalid={false}
                label="Secret"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSecret(e.target.value)
                }
              />
              {requiresPassphrase && (
                <TextInputField
                  isInvalid={false}
                  label="Passphrase"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassphrase(e.target.value)
                  }
                />
              )}
            </Pane>
            <Pane display="flex" justifyContent="space-between">
              <Pane
                flex="none"
                is="button"
                type="submit"
                className="outline-button"
                padding={minorScale(1)}
                minWidth={100}
                fontSize={16}
                disabled={loading}
                paddingBottom={6}
              >
                Continue
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}
