import { MarketsApi, ApiCandlestickInterval } from '../api';
import {
  ErrorCallback,
  IDatafeedChartApi,
  IExternalDatafeed,
  OnReadyCallback,
  ResolveCallback,
  SearchSymbolsCallback,
  LibrarySymbolInfo,
  ResolutionString,
  HistoryCallback,
  Bar,
  HistoryMetadata,
  SubscribeBarsCallback,
} from './charting_library/charting_library.min';
import { SUPPORTED_RESOLUTIONS, convertInterval } from './utils';
import { PublicSocket } from '../socket/publicSocket';

export interface TradingViewDataFeedConfig {
  exchanges: Array<string>;
  exchange: string;
  base: string;
  quote: string;
  publicSocket: PublicSocket;
}

export class TradingViewDataFeed
  implements IExternalDatafeed, IDatafeedChartApi {
  public exchanges: Array<string>;
  public exchange: string;
  public base: string;
  public quote: string;
  private publicSocket: PublicSocket;
  private interval?: ApiCandlestickInterval;
  private onCandle?: (candleStick: Array<any>) => any;

  constructor(config: TradingViewDataFeedConfig) {
    this.exchanges = config.exchanges;
    this.exchange = config.exchange;
    this.base = config.base;
    this.quote = config.quote;
    this.publicSocket = config.publicSocket;
  }

  onReady(callback: OnReadyCallback) {
    setTimeout(
      () =>
        callback({
          exchanges: this.exchanges.map(e => ({ value: e, name: e, desc: e })),
          symbols_types: [],
          supported_resolutions: SUPPORTED_RESOLUTIONS,
          supports_marks: true,
          supports_timescale_marks: true,
          supports_time: true,
        }),
      0
    );
  }

  searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback
  ) {
    onResult([
      {
        symbol: this.base + this.quote,
        full_name: this.exchange + this.base + this.quote,
        description: this.exchange + this.base + this.quote,
        exchange: this.exchange,
        ticker: `${this.exchange}/${this.base}/${this.quote}`,
        type: 'bitcoin',
      },
    ]);
  }

  async resolveSymbol(
    symbolName: string,
    onResolve: ResolveCallback,
    onError: ErrorCallback
  ) {
    console.log('resolve', symbolName);
    console.log('---------------------');
    let parts = symbolName.split(/[:-]/);
    let exchange = parts[0];
    let base_currency = parts[2];
    let quote_currency = parts[1];
    let market = await new MarketsApi().getMarket(
      this.exchange,
      this.base,
      this.quote
    );
    let minmov = parseFloat(
      (parseFloat(market.step_price) / 0.00000001).toFixed(8)
    );
    onResolve({
      name: market.symbol,
      full_name: symbolName,
      base_name: undefined,
      ticker: symbolName,
      description: market.symbol,
      format: 'price',
      type: 'bitcoin',
      session: '24x7',
      exchange: market.exchange,
      listed_exchange: market.exchange,
      timezone: 'America/Los_Angeles',
      pricescale: 100000000,
      minmov: minmov,
      fractional: false,
      minmove2: 0,
      has_intraday: true,
      supported_resolutions: SUPPORTED_RESOLUTIONS,
      intraday_multipliers: SUPPORTED_RESOLUTIONS.filter(s => s !== '1D'),
      has_seconds: false,
      seconds_multipliers: [],
      has_daily: true,
      has_weekly_and_monthly: false,
      has_empty_bars: true,
      force_session_rebuild: true,
      has_no_volume: false,
      volume_precision: 8,
      data_status: 'streaming',
      expired: false,
      expiration_date: undefined,
      sector: 'sector ???',
      industry: 'industry ???',
      currency_code: market.quote_currency,
    });
  }
  async getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    rangeStartDate: number,
    rangeEndDate: number,
    onResult: HistoryCallback,
    onError: ErrorCallback,
    isFirstCall: boolean
  ) {
    let parts = symbolInfo.full_name.split(/[:-]/).map(s => s.trim());

    let exchange = parts[0];
    let base_currency = parts[1];
    let quote_currency = parts[2];
    let interval = convertInterval(resolution);
    let start = rangeStartDate * 1000;
    let end = rangeEndDate * 1000;

    let ohlc_data = await new MarketsApi().getCandles(
      exchange,
      base_currency,
      quote_currency,
      interval,
      start,
      end
    );

    let bars: Bar[] = [];

    for (let i = 0; i < ohlc_data.length; i += 1) {
      bars.push({
        time: ohlc_data[i][0],
        open: parseFloat(ohlc_data[i][1]),
        high: parseFloat(ohlc_data[i][2]),
        low: parseFloat(ohlc_data[i][3]),
        close: parseFloat(ohlc_data[i][4]),
        volume: parseFloat(ohlc_data[i][5]),
      });
    }

    let meta: HistoryMetadata = {
      noData: false,
      nextTime: null,
    };

    onResult(bars, meta);
  }
  async getOrders(from: string, to: string) {
    console.log('get orders: ', from, to);
  }

  subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ): void {
    let parts = symbolInfo.full_name.split(/[:-]/);
    parts = parts.map(p => p.trim());
    let exchange = parts[0];
    let base_currency = parts[1];
    let quote_currency = parts[2];
    this.interval = convertInterval(resolution);

    this.onCandle = (candlestick: Array<any>) => {
      onTick({
        time: candlestick[0],
        open: parseFloat(candlestick[1]),
        high: parseFloat(candlestick[2]),
        low: parseFloat(candlestick[3]),
        close: parseFloat(candlestick[4]),
        volume: parseFloat(candlestick[5]),
      });
    };

    this.publicSocket.subscribeCandles(
      exchange,
      base_currency,
      quote_currency,
      this.interval,
      this.onCandle
    );
  }

  unsubscribeBars(listenerGuid: string): void {
    console.log('unsubscribeBars');
    this.publicSocket.unsubscribeCandles(
      this.exchange,
      this.base,
      this.quote,
      this.interval!,
      this.onCandle!
    );
  }
}
