import React from 'react';
import { Pane, minorScale, Button } from 'evergreen-ui';

export interface IChartBottomTabButtonProps {
  isActive: boolean;
  onClick: () => void;
}

export const ChartBottomTabButton: React.FC<IChartBottomTabButtonProps> = props => {
  return (
    <Pane
      padding={minorScale(1)}
      borderRight="1px solid #E0E3EB"
      borderBottom={`1px solid ${props.isActive ? 'transparent' : '#E0E3EB'} `}
    >
      <Button
        appearance="minimal"
        intent="none"
        fontSize={14}
        style={!props.isActive ? { color: '#131722' } : {}}
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    </Pane>
  );
};
