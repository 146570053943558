import {
  Heading,
  IconButton,
  majorScale,
  Pane,
  Position,
  Tooltip,
  TextInput,
  Button,
  Icon,
} from 'evergreen-ui';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApiMarket } from './api';
import { Chart } from './Chart';
import { CryptoTable } from './components/CryptoTable';
import { AuthContext } from './context/AuthContext';
import { TickerSearch } from './components/TickerSearch';

export interface IMainProps {
  markets: Array<ApiMarket>;
}

enum MainScreens {
  Default = 1,
  Chart,
}

export function Main(props: IMainProps) {
  const { handleLogin, handleLogout, user } = useContext(AuthContext);
  useEffect(() => {}, []);
  return (
    <Router>
      <Pane display="flex" flex="auto" flexDirection="column">
        <Pane
          backgroundColor="var(--black-1)"
          height={50}
          display="flex"
          alignItems="center"
          paddingX={majorScale(2)}
          borderBottom="3px solid #E0E3EB"
          color="var(--oc-gray-3)"
        >
          <Heading color="var(--oc-gray-3)" marginRight={majorScale(2)}>
            CRYPTOBOT
          </Heading>
          <TickerSearch markets={props.markets} />
          {user ? (
            <Pane marginLeft="auto" cursor="pointer" onClick={handleLogout}>
              Logout
            </Pane>
          ) : (
            <Pane marginLeft="auto" cursor="pointer" onClick={handleLogin}>
              Login
            </Pane>
          )}
        </Pane>
        <Pane display="flex" flex="auto" height="100%">
          <Pane
            width={50}
            display="flex"
            justifyContent="center"
            flex="none"
            paddingY={majorScale(1)}
            borderRight="3px solid #E0E3EB"
          >
            <Pane>
              <Tooltip content="Coins" position={Position.RIGHT}>
                <IconButton
                  appearance="minimal"
                  icon="user"
                  iconSize={18}
                  marginBottom={majorScale(2)}
                />
              </Tooltip>
              <Tooltip content="Balances" position={Position.RIGHT}>
                <IconButton
                  appearance="minimal"
                  icon="dollar"
                  iconSize={18}
                  marginBottom={majorScale(2)}
                />
              </Tooltip>
            </Pane>
          </Pane>
          <Pane display="flex" flex="auto" position="relative">
            <Switch>
              <Route
                exact
                path="/"
                render={({ history }) => {
                  return (
                    <CryptoTable
                      markets={props.markets}
                      handleRowClick={(market: ApiMarket) => {
                        history.push(
                          `/chart/${market.exchange}/${market.base_currency}/${market.quote_currency}`
                        );
                      }}
                    />
                  );
                }}
              ></Route>
              <Route path="/balances">
                <div>hello</div>
              </Route>
              <Route path="/chart/:exchange/:base/:quote">
                <Chart markets={props.markets} />
              </Route>
            </Switch>
          </Pane>
        </Pane>
      </Pane>
    </Router>
  );
}
