import React from 'react';
import { PublicSocket } from '../socket/publicSocket';
import UserSocket from '../socket/UserSocket';

const initial = {
  publicSocket: null,
  userSocket: null,
};

interface SocketContext {
  publicSocket: PublicSocket | null;
  userSocket: UserSocket | null;
}

export const SocketContext = React.createContext<SocketContext>(initial);
