import React from 'react';
import { Pane, majorScale } from 'evergreen-ui';

export interface ITabHeaderProps {}

export const TabHeader: React.FC<ITabHeaderProps> = props => {
  return (
    <Pane borderBottom="1px solid var(--border-gray)">
      <Pane
        height={48}
        display="flex"
        alignItems="center"
        padding={majorScale(1)}
        color="#131722"
        fontSize={16}
      >
        {props.children}
      </Pane>
    </Pane>
  );
};
