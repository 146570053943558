import { Auth } from 'aws-amplify';
import { ClientMessage, MessageType } from '../api';
import FavoritesStore from '../store/FavoritesStore';
import BalancesStore from '../store/BalancesStore';
import WebSocketObserver from './WebSocketObserver';
import AccountsStore from '../store/AccountsStore';

class UserSocket {
  private userSocket?: WebSocket;
  private observers: Set<WebSocketObserver> = new Set();
  public favoritesStore = new FavoritesStore();
  public balancesStore = new BalancesStore();
  public accountsStore = new AccountsStore();

  constructor() {
    this.connect();
    this.ping();
    this.observers.add(this.favoritesStore);
    this.observers.add(this.balancesStore);
    this.observers.add(this.accountsStore);
  }

  public addObserver(observer: WebSocketObserver) {
    this.observers.add(observer);
  }

  public removeObserver(observer: WebSocketObserver) {
    this.observers.delete(observer);
  }

  private connect() {
    console.log(Auth);
    Auth.currentSession().then(session => {
      this.userSocket = new WebSocket(
        'wss://socket.aobot.io/v1/account/' +
          session.getAccessToken().getJwtToken()
      );
      this.userSocket.onopen = event => this.onOpen(event);
      this.userSocket.onmessage = event => this.onMessage(event);
      this.userSocket.onclose = event => this.onClose(event);
      this.userSocket.onerror = event => this.onError(event);
    });
  }

  private reconnect = () => {
    if (this.userSocket != null) {
      this.userSocket.onopen = null;
      this.userSocket.onmessage = null;
      this.userSocket.onclose = null;
      this.userSocket.onerror = null;
    }
    this.connect();
  };

  private ping = () => {
    if (this.userSocket && this.userSocket.readyState === WebSocket.OPEN) {
      this.sendMessage({
        type: MessageType.PING,
      });
    }
    setTimeout(this.ping, 60000);
  };

  private onOpen(event: Event) {
    console.log('user socket onOpen');
    for (const observer of this.observers) {
      observer.onOpen(event);
    }
  }

  private onMessage(event: MessageEvent) {
    const message = JSON.parse(event.data);
    console.log(message);
    for (const observer of this.observers) {
      observer.onMessage(message);
    }
  }

  private onClose(event: CloseEvent) {
    console.log('user socket onClose');
    for (const observer of this.observers) {
      observer.onClose(event);
    }
    setTimeout(this.reconnect, 5000);
  }

  private onError(event: Event) {
    console.log('user socket onError');
    if (this.userSocket) {
      this.userSocket.close(100, 'user socket onError');
    }
  }

  private sendMessage(message: ClientMessage) {
    if (this.userSocket && this.userSocket.readyState === WebSocket.OPEN) {
      const json = JSON.stringify(message);
      console.log(json);
      this.userSocket.send(json);
    }
  }
}

export default UserSocket;
