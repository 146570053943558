import React from 'react';
import { ApiExchange } from '../api';

const initial = {
  accounts: {},
};

interface AccountContext {
  accounts: { [exchange: string]: ApiExchange };
}

export const AccountContext = React.createContext<AccountContext>(initial);
